import { Api } from './base/api';
export class LarekAPI extends Api {
    constructor(cdn, baseUrl, options) {
        super(baseUrl, options);
        this.cdn = cdn;
    }
    getProductList() {
        return this.get('/product').then((data) => data.items.map((item) => (Object.assign(Object.assign({}, item), { image: this.cdn + item.image }))));
    }
    getProductItem(id) {
        return this.get(`/product/${id}`).then((item) => (Object.assign(Object.assign({}, item), { image: this.cdn + item.image })));
    }
    orderProducts(order) {
        return this.post(`/order`, order).then((data) => data);
    }
}
