import { Component } from '../base/Component';
import { ensureElement } from '../../utils/utils';
export class Modal extends Component {
    constructor(container, events) {
        super(container);
        this.events = events;
        this._closeButton = ensureElement('.modal__close', container);
        this._content = ensureElement('.modal__content', container);
        this._closeButton.addEventListener('click', this.close.bind(this));
        this.container.addEventListener('mousedown', this.close.bind(this));
        this._content.addEventListener('mousedown', (event) => event.stopPropagation());
    }
    set content(value) {
        this._content.replaceChildren(value);
    }
    open() {
        this.container.classList.add('modal_active');
        this.events.emit('modal:open');
    }
    close() {
        this.container.classList.remove('modal_active');
        this.content = null;
        this.events.emit('modal:close');
    }
    render(data) {
        super.render(data);
        this.open();
        return this.container;
    }
}
