import { Component } from './base/Component';
import { createElement, ensureElement } from './../utils/utils';
export class Basket extends Component {
    constructor(container, events) {
        super(container);
        this.events = events;
        this._list = ensureElement('.basket__list', this.container);
        this._total = this.container.querySelector('.basket__price');
        this._button = this.container.querySelector('.basket__button');
        if (this._button) {
            this._button.addEventListener('click', () => {
                events.emit('order:open');
            });
        }
        this.items = [];
        this._button.disabled = true;
    }
    toggleButton(isDisabled) {
        this._button.disabled = isDisabled;
    }
    set items(items) {
        if (items.length) {
            this._list.replaceChildren(...items);
        }
        else {
            this._list.replaceChildren(createElement('p', {
                textContent: 'Корзина пуста',
            }));
        }
    }
    set total(total) {
        this.setText(this._total, `${total.toString()} синапсов`);
    }
}
