var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Component } from '../base/Component';
import { ensureElement } from '../../utils/utils';
export class Form extends Component {
    constructor(container, events) {
        super(container);
        this.container = container;
        this.events = events;
        this._submit = ensureElement('button[type=submit]', this.container);
        this._errors = ensureElement('.form__errors', this.container);
        this.container.addEventListener('input', (e) => {
            const target = e.target;
            const field = target.name;
            const value = target.value;
            this.onInputChange(field, value);
        });
        this.container.addEventListener('submit', (e) => {
            e.preventDefault();
            this.events.emit(`${this.container.name}:submit`);
        });
    }
    onInputChange(field, value) {
        this.events.emit(`${this.container.name}.${String(field)}:change`, {
            field,
            value,
        });
    }
    set valid(value) {
        this._submit.disabled = !value;
    }
    set errors(value) {
        this.setText(this._errors, value);
    }
    render(state) {
        const { valid, errors } = state, inputs = __rest(state, ["valid", "errors"]);
        super.render({ valid, errors });
        Object.assign(this, inputs);
        return this.container;
    }
}
