import { Form } from './common/Form';
import { ensureElement } from '../utils/utils';
export class DeliveryForm extends Form {
    constructor(container, events, actions) {
        super(container, events);
        this._cardButton = ensureElement('button[name="card"]', this.container);
        this._cashButton = ensureElement('button[name="cash"]', this.container);
        this._cardButton.classList.add('button_alt-active');
        if (actions === null || actions === void 0 ? void 0 : actions.onClick) {
            this._cardButton.addEventListener('click', actions.onClick);
            this._cashButton.addEventListener('click', actions.onClick);
        }
    }
    toggleButtons(target) {
        this._cardButton.classList.toggle('button_alt-active');
        this._cashButton.classList.toggle('button_alt-active');
    }
    set address(value) {
        this.container.elements.namedItem('address').value =
            value;
    }
}
export class ContactForm extends Form {
    constructor(container, events) {
        super(container, events);
    }
    set phone(value) {
        this.container.elements.namedItem('phone').value =
            value;
    }
    set email(value) {
        this.container.elements.namedItem('email').value =
            value;
    }
}
