import { Model } from './base/Model';
export class AppState extends Model {
    constructor() {
        super(...arguments);
        this.basket = [];
        this.order = {
            payment: 'online',
            address: '',
            email: '',
            phone: '',
            total: 0,
            items: [],
        };
        this.formErrors = {};
    }
    clearBasket() {
        this.basket = [];
        this.updateBasket();
    }
    clearOrder() {
        this.order = {
            payment: 'online',
            address: '',
            email: '',
            phone: '',
            total: 0,
            items: [],
        };
    }
    setCatalog(items) {
        this.catalog = items;
        this.emitChanges('items:changed', { catalog: this.catalog });
    }
    setPreview(item) {
        this.preview = item.id;
        this.emitChanges('preview:changed', item);
    }
    addToBasket(item) {
        if (this.basket.indexOf(item) < 0) {
            this.basket.push(item);
            this.updateBasket();
        }
    }
    removeFromBasket(item) {
        this.basket = this.basket.filter((it) => it != item);
        this.updateBasket();
    }
    updateBasket() {
        this.emitChanges('counter:changed', this.basket);
        this.emitChanges('basket:changed', this.basket);
    }
    setDeliveryField(field, value) {
        this.order[field] = value;
        if (this.validateDelivery()) {
            this.events.emit('delivery:ready', this.order);
        }
    }
    setContactField(field, value) {
        this.order[field] = value;
        if (this.validateContact()) {
            this.events.emit('contact:ready', this.order);
        }
    }
    validateDelivery() {
        const errors = {};
        const deliveryRegex = /^[а-яА-ЯёЁa-zA-Z0-9\s\/.,-]{10,}$/;
        if (!this.order.address) {
            errors.address = 'Необходимо указать адрес';
        }
        else if (!deliveryRegex.test(this.order.address)) {
            errors.address =
                'Адрес должен содержать только буквы, цифры, пробелы, точки, запятые и "/", состоять как минимум из 10 символов';
        }
        this.formErrors = errors;
        this.events.emit('formErrors:change', this.formErrors);
        return Object.keys(errors).length === 0;
    }
    validateContact() {
        const errors = {};
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const phoneRegex = /^\+7[0-9]{10}$/;
        if (!this.order.email) {
            errors.email = 'Необходимо указать email';
        }
        else if (!emailRegex.test(this.order.email)) {
            errors.email = 'Некорректный адрес электронной почты';
        }
        let phoneValue = this.order.phone;
        if (phoneValue.startsWith('8')) {
            phoneValue = '+7' + phoneValue.slice(1);
        }
        if (!phoneValue) {
            errors.phone = 'Необходимо указать телефон';
        }
        else if (!phoneRegex.test(phoneValue)) {
            errors.phone =
                'Некорректный формат номера телефона, номер следует указывать в формате +7ХХХХХХХХХХ';
        }
        else {
            this.order.phone = phoneValue;
        }
        this.formErrors = errors;
        this.events.emit('formErrors:change', this.formErrors);
        return Object.keys(errors).length === 0;
    }
}
