import { Component } from './base/Component';
import { ensureElement } from '../utils/utils';
export class Page extends Component {
    constructor(container, events) {
        super(container);
        this.events = events;
        this._counter = ensureElement('.header__basket-counter');
        this._catalog = ensureElement('.gallery');
        this._wrapper = ensureElement('.page__wrapper');
        this._basket = ensureElement('.header__basket');
        this._basket.addEventListener('click', () => {
            this.events.emit('basket:open');
        });
    }
    set counter(value) {
        this.setText(this._counter, String(value));
    }
    set catalog(items) {
        this._catalog.replaceChildren(...items);
    }
    set locked(value) {
        if (value) {
            this._wrapper.classList.add('page__wrapper_locked');
        }
        else {
            this._wrapper.classList.remove('page__wrapper_locked');
        }
    }
}
