import { Component } from './base/Component';
import { ensureElement } from './../utils/utils';
// Класс Success для отображения успешного выполнения операции
export class Success extends Component {
    constructor(container, actions) {
        super(container);
        // Инициализация элементов интерфейса
        this._close = ensureElement('.order-success__close', this.container);
        this._total = ensureElement('.order-success__description', this.container);
        // Подключение обработчика события закрытия окна
        if (actions === null || actions === void 0 ? void 0 : actions.onClick) {
            this._close.addEventListener('click', actions.onClick);
        }
    }
    // Установка текста итоговой информации
    set total(value) {
        this._total.textContent = `Списано ${value} синапсов`;
    }
}
