export const API_URL = `https://larek-api.nomoreparties.co/api/weblarek`;
export const CDN_URL = `https://larek-api.nomoreparties.co/content/weblarek`;
export const settings = {};
export const categoryClasses = {
    'софт-скил': 'card__category_soft',
    'хард-скил': 'card__category_hard',
    кнопка: 'card__category_button',
    дополнительное: 'card__category_additional',
    другое: 'card__category_other',
};
export const PaymentMethods = {
    card: 'online',
    cash: 'cash',
};
