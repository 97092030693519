import { Component } from './base/Component';
import { ensureElement } from '../utils/utils';
import { categoryClasses } from '../utils/constants';
export class Card extends Component {
    constructor(container, actions) {
        super(container);
        this._title = ensureElement('.card__title', container);
        this._price = ensureElement('.card__price', container);
        this._image = container.querySelector('.card__image');
        this._button = container.querySelector('.card__button');
        this._description = container.querySelector('.card__text');
        this._category = container.querySelector('.card__category');
        this._index = container.querySelector('.basket__item-index');
        if (actions === null || actions === void 0 ? void 0 : actions.onClick) {
            if (this._button) {
                this._button.addEventListener('click', actions.onClick);
            }
            else {
                container.addEventListener('click', actions.onClick);
            }
        }
    }
    disablePriceButton(value) {
        if (!value) {
            if (this._button) {
                this._button.disabled = true;
            }
        }
    }
    set id(value) {
        this.container.dataset.id = value;
    }
    get id() {
        return this.container.dataset.id || '';
    }
    set title(value) {
        this.setText(this._title, value);
    }
    get title() {
        return this._title.textContent || '';
    }
    set price(value) {
        this.setText(this._price, value ? `${value.toString()} синапсов` : 'Бесценно');
        this.disablePriceButton(value);
    }
    get price() {
        return Number(this._price.textContent || '');
    }
    set category(value) {
        this.setText(this._category, value);
        this._category.classList.add(categoryClasses[value]);
    }
    get category() {
        return this._category.textContent || '';
    }
    set index(value) {
        this._index.textContent = value;
    }
    get index() {
        return this._index.textContent || '';
    }
    set image(value) {
        this.setImage(this._image, value, this.title);
    }
    set description(value) {
        this.setText(this._description, value);
    }
    set buttonTitle(value) {
        if (this._button) {
            this._button.textContent = value;
        }
    }
}
